<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Audiences Index</h4>
      <router-link :to="{ name: 'TopicNew'}" tag="button" class="btn btn-success" type="button">Create Audience</router-link>
    </div>

    <div class="card">
      <div class="rainbow-background"></div>
      <b-checkbox id="users-archived" v-model="filters.is_archived" v-on:input="getTopics(currentPage, perPage)" switch>
        View Archived Audiences
      </b-checkbox>
      <!-- Table Headers and Search Bar -->
      <ransack-filtering :headerWidth="33" :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters" />

      <div class="table-responsive">
        <table class="card-table table">
          <tbody>
          <tr v-for="topic in topics" :key="topic.id" :id="topic.id">
            <td class="text-left">
              {{ topic.title }}
            </td>
            <td class="w-50">
              <div class="btn-group float-right">
                <router-link :to="{ name: 'TopicShow', params: { id: topic.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange" />

    </div>
  </div>
</template>

<script>
import { TopicService } from '@/common/services/topic.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';

export default {
  name: 'TopicIndex',
  components: {
    pagination: Pagination,
    RansackFiltering,
  },
  data() {
    return {
      topics: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Title'],
      searchParam: 'title_cont',
      filters: FilterService.defaultData(),
    };
  },
  created() {
    this.getTopics(this.currentPage, this.perPage);
  },
  methods: {
    onChange(page, perPage) {
      this.getTopics(page, perPage);
    },
    getTopics(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TopicService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.topics = response.data;
      });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getTopics(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/topics";
</style>
